import { mapState, mapGetters } from 'vuex';
import {
  fetchGroupsById,
  emptyCalificacion,
  fetchEvaluationPeriods,
  // fetchPerformanceLevel,
  fetchQualifications,
  // getStudentById,
  fetchQualificationStatus,
} from '../../../configuration/helpers/KnGroupsOptions';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import {
  fetchGradeDetails,
  // gradesPost,
  // gradesUpdate,
  postCreateGrade,
  postUpdateGrade,
} from '../../helpers/gradeOptions';
import { fetchSubjectStudents } from '../../helpers/syllabusOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
// import { getItem } from '../../../../api/api-methods';

export default {
  components: {
    KnFormTitle,
    KnLocalAlert,
  },
  mixins: [generalRepoMixin, paginationMixin, validationFormMixin],
  data() {
    return {
      valueDeterminate: 100,
      tab: 0,
      ungradedStudents: [], // Alumnos sin calificar
      studentsWithSubmittedGrades: [], // Alumnos con calificaciones enviadas
      studentsWithSavedGrades: [], // Alumnos con calificaciones por editar
      qualificationAprobada: [],
      selectedQualifications: [],
      selectedQualification: null,
      qualificationById: null,
      studentBandera: false,
      qualifications: [],
      routerName: 'Calificaciones',
      total: 0,
      totalArray: [],
      alertText: '',
      bandera: false,
      emptyCalificacion,
      promedio: 0,
      dataComentario: {},
      finalGrade: null,
      gradeId: null,
      studentId: null,
      studentArray: [],
      subjectArray: [],
      mixedArray: [],
      evaluationCriteria: [],
      expanded: [],
      evaluationPeriods: [],
      subjectStudents: [],
      errors: [],
      subjectTeacher: [],
      selectedStudentIds: [],
      selectedStudentId: [],
      promedioTotal: null,
      userName: null,
      isNewNotes: true,
      showAlert: false,
      group: undefined,
      loading: false,
      loadingTotal: false,
      warnings: [],
      materiaProfesorId: null,
      calificaciones: {
        id_boleta: 1,
        id_materia_alumno: null,
        id_periodo: null,
        id_estatus_calificacion: null,
        id_institucion_educativa: null,
        fecha_evaluacion: null,
      },
      evaluationType: 1,
      selectedItems: [],
      numericGrade: null,
      qualitativeGrade: null,
      performanceLevels: [],
      allSelected: false,
      evaluationTypes: [
        { id: 1, nombre: 'Calificación numérica' },
        { id: 2, nombre: 'Calificación cualitativa' },
      ],
      qualitativeOptions: [
        'Excelente',
        'Muy bueno',
        'Bueno',
        'Regular',
        'Insuficiente',
      ],
      headers: [
        { text: 'Materia', value: 'materia.nombre' },
        {
          text: '',
          sortable: false,
        },
        { text: 'ID', value: 'id', class: 'purple--text', sortable: false },
      ],
      secondHeaders: [
        {
          text: 'Alumno',
          value: 'nombreCompleto',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Criterios de evaluación',
          value: 'criterios_evaluacion',
          sortable: false,
          width: 250,
        },
        { text: 'Comentarios', value: 'comentarios', sortable: false },
        { text: 'Estatus calificación', value: 'estatus', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, width: 100 },
      ],
      thirdHeaders: [
        {
          text: 'Alumno',
          value: 'nombreCompleto',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Criterios de evaluación',
          value: 'criterios_evaluacion',
          sortable: false,
          width: 250,
        },
        { text: 'Comentarios', value: 'comentarios', sortable: false },
        {
          text: 'Estatus calificación',
          value: 'grade.estatus_calificacion.dato',
          sortable: false,
        },
        { text: 'Acciones', value: 'actions', sortable: false, width: 100 },
      ],
      fourthHeaders: [
        {
          text: 'Alumno',
          value: 'nombreCompleto',
          sortable: false,
        },
        {
          text: 'Calificación',
          value: 'grade.calificacion_numerica',
          sortable: false,
        },
        { text: 'Periodo', value: 'grade.periodo.dato', sortable: false },
        {
          text: 'Estatus',
          value: 'grade.estatus_calificacion.dato',
          sortable: false,
        },
      ],
      editar: false,
      materiaProfesor: [],
      materiaAlumnoById: null,
      draftStatus: null,
      pendingStatus: null,
    };
  },

  async created() {
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.gradeId = this.validateId(paramId);
        // await this.getPaginatedPerformanceLevel(1);
        await this.loadDataGrade();
        this.periodoEvaluacion = await this.getPaginatedevaluationPeriods(1);
        this.draftStatus = await this.getGradeStatus('borrador');
        this.pendingStatus = await this.getGradeStatus('pendiente');
        this.calificaciones.id_institucion_educativa = this.institutionId;
        //await this.getPaginatedSubjectTeachers(1)
        this.studentArray.forEach((student) => {
          student.estatus = 'Sin asignar';
        });
      } else return undefined;
    } finally {
      this.loadingPage = false;
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),

    successAlertText() {
      return this.isNewNotes
        ? `Calificación mandada con exito! \nNombre: ${this.selectedStudentIds[0].datos_personales}, promedio: ${this.promedio}`
        : '';
    },
    dangerAlertText() {
      return `Ocurrio un error inesperado`;
    },
    dangerAlertType() {
      return 'warning';
    },
    successAlertType() {
      return this.isNewNotes ? 'success' : 'info';
    },
    isNumericOrDefault() {
      return this.evaluationType === 1 || this.evaluationType === null;
    },
    // Computada para mostrar el v-select si es calificación cualitativa
    isQualitative() {
      return this.evaluationType === 2;
    },
  },
  methods: {
    //#region peticiones get
    async getGradeStatus(name) {
      try {
        const { ok, data } = await fetchQualificationStatus({
          dato: name,
          systemStatus: true,
        });
        if (ok && data.length) {
          return data[0];
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    async getPaginatedevaluationPeriods(page = 1) {
      try {
        this.evaluationPeriods = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;
          const { ok, data, message, count } = await fetchEvaluationPeriods({
            institutionId: this.institutionId,
            systemStatus: true,
            startDate: '2024-08-01',
            endDate: '2025-01-31',
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            this.setPaginationCount(count);
            for (const evaluationPeriod of data) {
              this.evaluationPeriods.push({ ...evaluationPeriod });
            }
          } else {
            console.error(
              'No se pudieron obtener los periodos de evaluación',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },

    async getPaginatedSubjectStudents(page = 1) {
      try {
        this.subjectStudents = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;
          // console.log('Estudiante seleccionado', this.isTeacherEmploye);
          for (const element of this.selectedStudentIds) {
            if (element !== null && element !== undefined) {
              const { ok, data, message, count } = await fetchSubjectStudents({
                institutionId: this.institutionId,
                systemStatus: true,
                studentId: element.id,
                subjectTeacherId:
                  this.selectedStudentIds[0].materia_alumno[0].id, //this.materiaProfesorId,
                limit: this.pagination.limit,
                offset: this.pagination.offset,
              });

              if (ok) {
                this.setPaginationCount(count);
                for (const subjectStudents of data) {
                  this.subjectStudents.push({ ...subjectStudents });
                }
                // console.log('Materia profesor', this.subjectArray);
              } else {
                console.error(
                  'No se pudieron obtener los periodos de evaluación',
                  message
                );
              }

              this.loading = false;
            }
          }
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },
    /**
     * Obtiene calificaciones con estatus Borrador y Rechazado y
     * las establece en el array correspondiente
     */
    async getDraftGrades() {
      try {
        // Obtener calificaciones
        this.loading = true;
        for (const student of this.studentsWithSavedGrades) {
          student.grade = null;
          const { ok, data, message } = await fetchQualifications({
            estatus_calificacion: 'Borrador,Rechazada',
            systemStatus: true,
            materia_alumno: student.currentSubjectStudent.id,
            periodo: this.calificaciones.id_periodo,
          });

          if (ok) {
            const grade = data.length ? data[0] : null;
            student.grade = grade;

            const {
              ok: okDetail,
              data: dataDetail,
              message: messageDetail,
            } = await fetchGradeDetails({
              gradeId: grade.id,
              systemStatus: true,
              limit: 50,
            });
            if (okDetail) {
              student.criterios_evaluacion = [...dataDetail];
              // console.log('evaluationCriteria', student.criterios_evaluacion);
            } else {
              console.error(
                'Error al obtener detalle de calificacion',
                messageDetail
              );
            }
          } else {
            console.error('Error al obtener calificaciones', message);
          }
        }
        // console.log(
        //   'getDraftGrades studentsWithSavedGrades',
        //   this.studentsWithSavedGrades
        // );
      } catch (error) {
        console.error(
          'Error al intentar obtener calificaciones de alumnos en edición',
          error
        );
      } finally {
        this.loading = false;
      }
    },
    /**
     * Obtiene calificaciones con estatus Pendiente, En revisión y
     * Aprobada y las establece en el array correspondiente
     */
    async getSubmittedGrades() {
      try {
        // Obtener calificaciones
        this.loading = true;
        for (const student of this.studentsWithSubmittedGrades) {
          student.grade = null;
          const { ok, data, message } = await fetchQualifications({
            estatus_calificacion: 'Pendiente,En revisión,Aprobada',
            systemStatus: true,
            materia_alumno: student.currentSubjectStudent.id,
            periodo: this.calificaciones.id_periodo,
          });

          if (ok) {
            const grade = data.length ? data[0] : null;
            student.grade = grade;
          } else {
            console.error('Error al obtener calificaciones', message);
          }
        }
        // console.log(
        //   'getSubmittedGrades studentsWithSubmittedGrades',
        //   this.studentsWithSubmittedGrades
        // );
      } catch (error) {
        console.error(
          'Error al intentar obtener calificaciones de alumnos enviados',
          error
        );
      } finally {
        this.loading = false;
      }
    },
    async loadDataGrade() {
      try {
        const responseData = await fetchGroupsById(this.gradeId);
        this.group = responseData;
        this.subjectTeacher = responseData.materia_profesor;
        this.studentArray = responseData.alumnos;

        this.materiaProfesor = this.isTeacherEmploye
          ? this.subjectTeacher.filter(
              (st) => st.profesor.id === this.employeeId
            )
          : this.subjectTeacher;
      } catch (error) {
        console.error('Error al cargar los alumnos:', error);
      }
    },
    getCriteriosBySubject(subjectId) {
      const subject = this.subjectTeacher.find(
        (sub) => sub.materia.id === subjectId
      );
      if (subject) {
        this.studentArray.map((student) => {
          student.comentarios;
          student.criterios_evaluacion = subject.criterios_evaluacion.map(
            (ce) => {
              return {
                ...ce,
                nuevoPorcentaje: 0,
                total: 0,
                promedio: 0,
                qualitativeRating: 0,
                comentarios: '',
              };
            }
          );
        });
        // console.log('criterios de evaluacion ', subject.criterios_evaluacion);
      }
      return subject ? subject.criterios_evaluacion : [];
    },
    async onTabChange() {
      try {
        if (this.tab === 1) {
          await this.getDraftGrades();
        } else if (this.tab === 2) {
          await this.getSubmittedGrades();
        }
      } catch (error) {
        console.error('Error al hacer la petición:', error);
      }
    },
    //#endregion
    //#region tabla expandida
    onSelect(selectedItems) {
      // console.log('onSelect selectedItems', selectedItems);
      if (selectedItems.length) {
        this.selectedStudentId = selectedItems[0].id;
      } else {
        this.selectedStudentId = null;
      }
    },
    isItemSelected(itemId) {
      return this.selectedStudentId === itemId;
    },

    onSelectQualification(selectedItems) {
      console.log('onSelectQualification selectedItems', selectedItems);
    },
    isItemSelectedQualification(itemId) {
      return this.selectedStudentId === itemId;
    },
    async onItemExpanded({ item, value }) {
      this.ungradedStudents = [];
      this.studentsWithSavedGrades = [];
      this.studentsWithSubmittedGrades = [];
      this.tab = 0;

      this.expandedItemId = value ? item.id : null;
      // console.log('onItemExpanded item', item);

      this.getCriteriosBySubject(item.materia.id);
      this.studentArray.forEach((student) => {
        student.nombreCompleto = getFullName(student.datos_personales);
        student.isSend = false;
        student.materia_alumno = this.materiaProfesor.filter(
          (x) => x.materia.id === this.expandedItemId
        );
        student.comentarios = '';
      });

      if (this.tab === 0) {
        for (const student of this.studentArray) {
          // Esto para obtener materia alumno del alumno
          const { ok, data } = await fetchSubjectStudents({
            institutionId: this.institutionId,
            systemStatus: true,
            studentId: student.id,
            subjectTeacherId: item.id, //this.materiaProfesorId,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            if (data.length) {
              student.currentSubjectStudent = data[0];
            } else {
              student.currentSubjectStudent = null;
            }
          } else {
            student.currentSubjectStudent = null;
          }

          // Checamos si el alumno tiene o no calificaciones
          // para saber si se va al array sin calificar

          if (student.currentSubjectStudent) {
            const { ok: okGrades, count: countGrades } =
              await fetchQualifications({
                systemStatus: true,
                materia_alumno: student.currentSubjectStudent.id,
                periodo: this.calificaciones.id_periodo,
                limit: 1,
              });
            if (okGrades && !countGrades) {
              this.ungradedStudents.push(student);
            } else {
              const { ok: okSaved, count: countSaved } =
                await fetchQualifications({
                  systemStatus: true,
                  materia_alumno: student.currentSubjectStudent.id,
                  periodo: this.calificaciones.id_periodo,
                  estatus_calificacion: 'Borrador,Rechazada',
                  limit: 1,
                });
              if (okSaved && countSaved) {
                this.studentsWithSavedGrades.push(student);
              } else {
                const { ok: okSubmitted, count: countSubmitted } =
                  await fetchQualifications({
                    systemStatus: true,
                    materia_alumno: student.currentSubjectStudent.id,
                    periodo: this.calificaciones.id_periodo,
                    estatus_calificacion: 'Pendiente,En revisión,Aprobada',
                    limit: 1,
                  });
                if (okSubmitted && countSubmitted) {
                  this.studentsWithSubmittedGrades.push(student);
                }
              }
            }
          } else {
            console.error('No se pudo validar la exitencia de materiaAlumno');
          }
        }
      }
      // console.log('Alumnos sin calificar', this.ungradedStudents);
      // console.log('Alumnos en edición', this.studentsWithSavedGrades);
      // console.log('Alumnos enviados', this.studentsWithSubmittedGrades);
    },
    /**
     * Valida si se ha seleccionado periodo y fecha de evaluación
     * @returns true o false
     */
    hasBasicData() {
      if (
        !this.calificaciones.id_periodo ||
        !this.calificaciones.fecha_evaluacion
      ) {
        this.setWarningState(
          'Es necesario seleccionar periodo y fecha de evaluación antes de continuar.'
        );
        return false; // Salir de la función si no están asignados
      }
      return true;
    },
    /**
     * Costruye objeto que se necesita enviar para crear o actualizar calificación
     * tomando en cuenta la lista desde la cual se mande a llamar
     * @param {Object} student item seleccionado desde la tabla
     * @param {String} status nombre del estatus que se va a utilizar para las calificaciones
     * @returns Objeto necesario para crear o actualizar calificación
     */
    buildGradeData(student, status = 'borrador') {
      const evaluationDate = new Date(
        this.calificaciones.fecha_evaluacion
      ).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      switch (this.tab) {
        case 0:
          return {
            id_boleta: 1,
            id_materia_alumno: student.currentSubjectStudent.id,
            id_periodo: this.calificaciones.id_periodo,
            comentarios: student.comentarios,
            fecha_evaluacion: evaluationDate,
            id_estatus_calificacion:
              status === 'borrador'
                ? this.draftStatus.id
                : this.pendingStatus.id,
            id_institucion_educativa: this.institutionId,
            detalle_calificaciones: student.criterios_evaluacion.map((ce) => {
              return {
                id_criterio_evaluacion: ce.id,
                puntaje_obtenido: ce.nuevoPorcentaje,
                puntaje_total: ce.porcentaje,
              };
            }),
          };
        case 1:
          return {
            id_calificacion: student.grade.id,
            id_boleta: 1,
            id_materia_alumno: student.currentSubjectStudent.id,
            id_periodo: this.calificaciones.id_periodo,
            comentarios: student.grade.comentarios,
            fecha_evaluacion: evaluationDate,
            id_estatus_calificacion:
              status === 'borrador'
                ? this.draftStatus.id
                : this.pendingStatus.id,
            id_institucion_educativa: this.institutionId,
            detalle_calificaciones: student.criterios_evaluacion.map((ce) => {
              return {
                id: ce.id,
                id_criterio_evaluacion: ce.criterio_evaluacion.id,
                puntaje_obtenido: ce.puntaje_obtenido,
                puntaje_total: ce.puntaje_total,
              };
            }),
          };
      }
    },
    //#endregion
    //#region POST calificaciones
    /**
     * Guarda calificación. Dependiendo de la lista
     * desde la cual se llame se manda a crear o actualizar
     * con estatus borrador
     * @param {Object} selectedStudent Item seleccionado
     * @returns
     */
    async saveGrade(selectedStudent) {
      if (!this.hasBasicData()) return;
      this.setLoadingState(true, 'Guardando calificación', 'info');
      // console.log('saveGrade selectedStudent', selectedStudent);

      const dataToSend = this.buildGradeData(selectedStudent);
      // console.log('sendNewGrade dataToSend', dataToSend);
      const { ok, message } =
        this.tab === 0
          ? await postCreateGrade(dataToSend)
          : await postUpdateGrade(dataToSend);

      if (!ok) {
        // console.error('Error en la respuesta de la API:', message);
        this.setErrorState(message);
      } else {
        this.setSuccessState('Calificación guardada exitosamente');
        this.expanded = [];
      }
    },
    /**
     * Envía calificación. Dependiendo de la lista desde
     * la cual se llame se manda a crear o actualzar con
     * estatus pendiente.
     * @param {Object} selectedStudent Item seleccionado
     * @returns
     */
    async sendGrade(selectedStudent) {
      if (!this.hasBasicData()) return;
      this.setLoadingState(true, 'Enviando calificación', 'info');
      // console.log('sendNewGrade selectedStudent', selectedStudent);

      const dataToSend = this.buildGradeData(selectedStudent, 'pendiente');
      // console.log('sendNewGrade dataToSend', dataToSend);

      const { ok, message } =
        this.tab === 0
          ? await postCreateGrade(dataToSend)
          : await postUpdateGrade(dataToSend);

      if (!ok) {
        // console.error('Error en la respuesta de la API:', message);
        this.setErrorState(message);
      } else {
        this.setSuccessState('Calificación enviada exitosamente');
        this.expanded = [];
      }
    },
    //#endregion
    //#region procesar calificaciones y criterios de evaluacion
    actualizarTotal(criterio, index, criterioId) {
      // Verifica si se proporcionó el ID del criterio

      if (!criterioId) {
        console.error('No se ha proporcionado un ID del criterio.');
        return;
      }
      // Referencias a los elementos correspondientes del DOM
      const criterioInput = this.$refs[`criterio-${criterioId}-${index}`];
      const totalContainer = this.$refs[`total-${criterioId}-${index}`];

      // Verifica que las referencias existen
      if (criterioInput && totalContainer) {
        const { porcentaje, nuevoPorcentaje, qualitativeRating } = criterio;

        // Si se selecciona una calificación cualitativa, aplica los valores correspondientes
        if (qualitativeRating) {
          criterio.total = this.getQualitativeTotal(
            criterio,
            qualitativeRating
          );
        } else {
          // Calcula el total para el tipo numérico (si no es cualitativa)
          criterio.total = this.calculateNumericTotal(
            nuevoPorcentaje,
            porcentaje
          );
        }

        const total = criterio.total;

        // Actualiza el contenido del contenedor con el nuevo total
        this.updateTotalDisplay(totalContainer, total);
        this.processCriterio(criterio);
      } else {
        console.error(
          `No se encontraron las referencias para el criterio con id ${criterioId} y el índice ${index}`
        );
      }
    },
    updateNumericalTotal(criterio) {
      this.loadingTotal = true;
      // console.log('updateNumericTotal criterio', criterio);

      criterio.total = this.calculateNumericTotal(
        criterio.nuevoPorcentaje,
        criterio.porcentaje
      );
      this.loadingTotal = false;
    },
    updateTotalPerformanceLevel(criterio) {
      this.loadingTotal = true;
      criterio.total = this.calculateQualitativeTotal(criterio);
      this.loadingTotal = false;
    },
    calculateQualitativeTotal(criterio) {
      if (!criterio && !criterio.qualitativeRating) return;
      const excelente = 100;
      const muyBueno = 95;
      const bueno = 85;
      const regular = 75;
      const insuficiente = 50;
      switch (criterio.qualitativeRating) {
        case 'Excelente':
          criterio.nuevoPorcentaje = Math.trunc(
            excelente * (criterio.porcentaje / 100)
          );
          return criterio.nuevoPorcentaje;
        case 'Muy bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            muyBueno * (criterio.porcentaje / 100)
          );
          return criterio.nuevoPorcentaje;
        case 'Bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            bueno * (criterio.porcentaje / 100)
          );
          return criterio.nuevoPorcentaje;
        case 'Regular':
          criterio.nuevoPorcentaje = Math.trunc(
            regular * (criterio.porcentaje / 100)
          );
          return criterio.nuevoPorcentaje;
        case 'Insuficiente':
          criterio.nuevoPorcentaje = Math.trunc(
            insuficiente * (criterio.porcentaje / 100)
          );
          return criterio.nuevoPorcentaje;
        default:
          return 0; // Valor por defecto si no coincide ninguna opción
      }
    },
    // Método para obtener el total basado en la calificación cualitativa
    getQualitativeTotal(criterio, qualitativeRating) {
      if (!criterio && !qualitativeRating) return;
      const excelente = 100;
      const muyBueno = 95;
      const bueno = 85;
      const regular = 75;
      const insuficiente = 50;
      switch (qualitativeRating) {
        case 'Excelente':
          criterio.nuevoPorcentaje = Math.trunc(
            excelente * (criterio.porcentaje / 100)
          );
          return excelente;
        case 'Muy bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            muyBueno * (criterio.porcentaje / 100)
          );
          return muyBueno;
        case 'Bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            bueno * (criterio.porcentaje / 100)
          );
          return bueno;
        case 'Regular':
          criterio.nuevoPorcentaje = Math.trunc(
            regular * (criterio.porcentaje / 100)
          );
          return regular;
        case 'Insuficiente':
          criterio.nuevoPorcentaje = Math.trunc(
            insuficiente * (criterio.porcentaje / 100)
          );
          return insuficiente;
        default:
          return 0; // Valor por defecto si no coincide ninguna opción
      }
    },
    calculateNumericTotalEvaluationCualitativa(nuevoPorcentaje, porcentaje) {
      // Verifica que 'nuevoPorcentaje' y 'porcentaje' sean números válidos
      const nuevoPorcentajeNum = parseFloat(nuevoPorcentaje);
      const porcentajeNum = parseFloat(porcentaje);

      if (
        !isNaN(nuevoPorcentajeNum) &&
        !isNaN(porcentajeNum) &&
        porcentajeNum > 0
      ) {
        return (
          Math.trunc(((nuevoPorcentajeNum * 100) / porcentajeNum) * 100) / 100
        );
      } else {
        console.error(
          'Datos inválidos para cálculo numérico:',
          nuevoPorcentaje,
          porcentaje
        );
        return 0;
      }
    },
    // Método para calcular el total basado en porcentaje numérico
    calculateNumericTotal(nuevoPorcentaje, porcentaje) {
      if (!nuevoPorcentaje || !porcentaje) {
        return 0; // Si no hay datos, el total es 0
      }
      return (
        Math.trunc(
          ((Number(nuevoPorcentaje) * 100) / Number(porcentaje)) * 100
        ) / 100
      );
    },

    // Método para actualizar el contenido del contenedor de total
    updateTotalDisplay(totalContainer, total) {
      // Limpia el contenido existente
      totalContainer[0].innerHTML = '';

      // Crea el nuevo elemento de texto con el total
      const text = document.createElement('p');
      text.textContent = `total: ${total}`;

      // Inserta el nuevo elemento en el contenedor
      totalContainer[0].appendChild(text);
    },

    processCriterio(criterio) {
      const total = criterio.total;
      this.totalArray.push(total);
      criterio.promedio = this.cularPromedioTotal(this.totalArray);
      this.promedio = criterio.promedio;
    },
    cularPromedioTotal(objeto) {
      // Verificar que el objeto tiene la propiedad "total"

      // Calcular el promedio de los valores en la propiedad "total"
      let sumaTotal = 0;

      // Sumar todos los valores en la propiedad "total" usando un bucle for
      for (let i = 0; i < objeto.length; i++) {
        sumaTotal += objeto[i];
      }

      // Calcular el promedio
      const promedio = sumaTotal / objeto.length;

      // Truncar el promedio a 2 decimales
      const promedioTruncado = Math.floor(promedio * 100) / 100;
      return promedioTruncado;
    },
    //#endregion
    //#region validaciones
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success') {
        this.returnToTable();
      } else if (this.alertType === 'warning' || this.alertType === 'info') {
        this.closeAlert();
      } else {
        this.returnToTable();
      }
    },
    continueAdding() {
      this.selectedStudentIds = [];
      this.closeAlert();
      this.clearSelection();
    },
    onComentarioInput(value, itemId) {
      this.dataComentario[itemId] = value;
      // Aquí puedes decidir si quieres hacer algo más que pueda afectar el primer template
    },
    updateComentario(id, value) {
      this.dataComentario[id] = value;
    },
    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
    getGroupImage(group) {
      if (!group || !group.imagen_grupo || !group.imagen_grupo.imagen) {
        return require('../../../../assets/images/huellita.png'); // Default image
      } else {
        return group.imagen_grupo.imagen;
      }
    },
    getSchoolYear(group) {
      if (!group || !group.ciclo_escolar || !group.ciclo_escolar.dato) {
        return 'Estimulación temprana';
      } else {
        return group.ciclo_escolar.dato;
      }
    },
    //#endregion
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = this.errors.join(', ');
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
      this.alertText = '';
      this.alertType = '';
      this.alertColor = '';
    },
    //#endregion
    cancel() {
      this.returnToTable();
    },
    clearSelection() {
      this.selectedStudentIds = [];
      this.selectedStudentId = null;
      this.studentId = null;
    },
    saveStudents() {
      // Recupera el array existente de estudiantes desde localStorage
      const existingStudents =
        JSON.parse(localStorage.getItem('students')) || [];

      // Filtra los nuevos estudiantes que no estén ya en el array
      const newStudents = this.selectedStudentIds.filter(
        (student) =>
          !existingStudents.some((existing) => existing.id === student.id)
      );

      // Combina los arrays de estudiantes existentes y nuevos
      const updatedStudents = [...existingStudents, ...newStudents];

      // Guarda el array actualizado en localStorage
      localStorage.setItem('students', JSON.stringify(updatedStudents));
      this.setWarningState('Su calificación se ha guardado en borrador');
    },
  },
};
